html {
  scroll-behavior: smooth;
}
.animation-appear {
  animation: appearingK 0.2s linear 0s 1;
  opacity: 1;
}

@keyframes appearingK {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animation-disappear {
  animation: disappearingK 0.2s linear 0s 1;
  opacity: 0;
}
@keyframes disappearingK {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.special-font-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 3px;
}
.special-font-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
}

.rotating {
  animation: rotatingAnim 0.5s linear infinite;
}

@keyframes rotatingAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
